import React, {
    useState
} from 'react';
import env from "env";
import styled from "styled-components";
import {
    detectMobileDevice
} from "helpers/checkIsNativeDevice";
import IMAGES from 'constants/Images';
import {
    isInAppWebview
} from "utils/ClientAppUtils";
import {
    QRCodeSVG
} from 'qrcode.react';
import useInterval from 'hooks/useInterval';
import {
    checkRPDStatus,
    mockRPDStatus,
    generateRPDRequest
} from 'api/rest';
import {
    handleApiError,
    triggerToast
} from "helpers";
import {
    ActionButton,
    Spinner
} from 'screens/commons';


const RPD_STATUS = {
    "BAV_REVERSE_PENNY_DROP_CREATED": "BAV_REVERSE_PENNY_DROP_CREATED",
    "BAV_REVERSE_PENNY_DROP_FAILED": "BAV_REVERSE_PENNY_DROP_FAILED",
    "BAV_REVERSE_PENNY_DROP_EXPIRED": "BAV_REVERSE_PENNY_DROP_EXPIRED",
    "BAV_REVERSE_PENNY_DROP_PAYMENT_SUCCESSFUL": "BAV_REVERSE_PENNY_DROP_PAYMENT_SUCCESSFUL",
    "BAV_REVERSE_PENNY_DROP_PAYMENT_FAILED": "BAV_REVERSE_PENNY_DROP_PAYMENT_FAILED"
};

const transformUPILinkForIOS = (upiUrl) => {
    if (!upiUrl) return {};

    const deeplinks = {
        phonepe:
            upiUrl.replace("upi://", "phonepe://"),
        gpay: upiUrl.replace("upi://", "tez://upi/"),
        paytm:
            upiUrl.replace("upi://", "paytmmp://"),
        bharatpe:
            upiUrl.replace("upi://", "bharatpe://")
    };
    return deeplinks;
};


function RPD({rpdDetails, updateStatus}) {
    const {isMobile, isAndroid} = detectMobileDevice();
    const [requestDetails, setRequestDetails] = useState(null);

    const [isSkippingRPD, setIsSkippingRPD] = useState(false);

    const [isMocking, setIsMocking] = useState(false);

    const mockStatus = (status) => {
        setIsMocking(true);
        mockRPDStatus(rpdDetails.id, status).then(() => {
            triggerToast("Mock Success", "success");
        }).catch((e) => {
            triggerToast("Mock failed");
        }).finally(() => {
            setIsMocking(false);
        });
    };

    const checkRequestStatus = () => {
        checkRPDStatus(rpdDetails.id).then(res => {
            setRequestDetails(res.data);

            switch (res.data.status) {
                case RPD_STATUS.BAV_REVERSE_PENNY_DROP_EXPIRED:
                    triggerToast("Current Penny drop request expired","info");
                    break;
                case RPD_STATUS.BAV_REVERSE_PENNY_DROP_FAILED:
                    triggerToast("Penny drop verification failed. Proceeding to alternative method","info");
                    break;
                case RPD_STATUS.BAV_REVERSE_PENNY_DROP_PAYMENT_SUCCESSFUL:
                    break;
                case RPD_STATUS.BAV_REVERSE_PENNY_DROP_PAYMENT_FAILED:
                    triggerToast("Payment failed. Proceeding to alternative method","info");
                    break;
                case RPD_STATUS.BAV_REVERSE_PENNY_DROP_CREATED:
                default:
                    // Initial state, no toast needed
                    break;
            }
            if (res?.data?.status !== RPD_STATUS.BAV_REVERSE_PENNY_DROP_CREATED) updateStatus();
        }).catch(e => {
            triggerToast("Payment failed. Proceeding to alternative method","info");
            updateStatus();
        });
    };

    useInterval(checkRequestStatus, 3500);
    const isRPDSuccess = requestDetails?.status === RPD_STATUS.BAV_REVERSE_PENNY_DROP_PAYMENT_SUCCESSFUL;

    const enterDetailsManually = () =>{
        setIsSkippingRPD(true);
        
        generateRPDRequest({skipRpd:true}).then(res => {
            updateStatus();
        }).catch(e => {
            handleApiError(e);
        }).finally(()=>{
            setIsSkippingRPD(false);
        });
    };

    const renderUPIDeepLinks = () => {
        const {phonepe, gpay, paytm} = transformUPILinkForIOS(rpdDetails?.upiLink);
        return <IosLinksContainer>
            <Heading className='text-start w-100' >
                Verify Via:
            </Heading>
            <IosLinks>
                <a className='upi-link-code' href={phonepe}>
                    <UpiAppIcon className="upi-logo" src={IMAGES.phonePe} />
                    PhonePe
                    <ArrowIcon src={IMAGES.arrowHeadRight} />
                </a>
                <a className='upi-link-code' href={gpay}>
                    <UpiAppIcon className="upi-logo" src={IMAGES.gpay} />
                    Google Pay
                    <ArrowIcon src={IMAGES.arrowHeadRight} />
                </a>
                <a className='upi-link-code' href={paytm}>
                    <UpiAppIcon className="upi-logo" src={IMAGES.paytm} />
                    Paytm
                    <ArrowIcon src={IMAGES.arrowHeadRight} />
                </a>
                {isAndroid ? <a className='upi-link-code' href={rpdDetails?.upiLink}>
                    <UpiAppIcon className="upi-logo" src={IMAGES.upiLogo} />
                    Any UPI app
                    <ArrowIcon src={IMAGES.arrowHeadRight} />
                </a> : null}
            </IosLinks>
        </IosLinksContainer>;
    };

    const renderAddDetailsManually = () => <>
        <InfoText className='manual-selection-or-txt'>— OR —</InfoText>
        <InfoText className='manual-enter-text'>Don't have access to UPI apps?</InfoText>
        <ActionButton
            buttonText={'Verify Bank Details Manually →'}
            callback={enterDetailsManually}
            isLoading={isSkippingRPD}
            isDisabled={isSkippingRPD}
            className="enter-manually-btn"
            spinnerColor='blue'
        />
    </>;

    const renderQRInDesktop = () => <DesktopQRCode>
        <P className='sub-text'>Scan QR code with any UPI app</P>
        <UpiIcons>
            <UpiAppIcon className="upi-logo" src={IMAGES.phonePe} />
            <UpiAppIcon className="upi-logo" src={IMAGES.gpay} />
            <UpiAppIcon className="upi-logo upi-logo-paytm" src={IMAGES.paytm} />
            <UpiAppIcon className="upi-logo" src={IMAGES.bharathPay} />
            <UpiAppIcon className="upi-logo upi-logo-whatsapp" src={IMAGES.whatsapp} />
        </UpiIcons>
        <QRCodeSVG className='desktop-qr-code' value={rpdDetails?.upiLink} size={256} fgColor={"#290478"} />
    </DesktopQRCode>;

    const renderRPDQRAndLinks = () => {
        if (isMobile)
            return <>
                {renderUPIDeepLinks()}
                {renderAddDetailsManually()}
            </>;
        return <>
            {renderQRInDesktop()}
            {renderAddDetailsManually()}
        </>;
    };

    const renderMockBtns = () => <MockPaymentBtns>
        {isMocking ? <Spinner color='blue' /> : null}
        <MockBtn className='mock-success' onClick={() => mockStatus('successful')}>Mock Payment Success</MockBtn>
        <MockBtn className='mock-failure' onClick={() => mockStatus('expired')}>Mock Payment Failure</MockBtn>
    </MockPaymentBtns>;

    return <Wrapper>
        <Heading className='padded-text'>Verify your Bank Account via UPI</Heading>
        <InfoText className='padded-text'>Pay ₹1 from your account for verification, it will be refunded within 3 working days</InfoText>
        {env.IS_PRODUCTION ? null : renderMockBtns()}
        {!isRPDSuccess ? renderRPDQRAndLinks() : null}
        {isRPDSuccess ?
            <SuccessWrapper>
                <SuccessText>Payment done! <span role="img" aria-label="check">✅</span></SuccessText>
                <Amount>₹1.00</Amount>
                <VerificationText>Bank Account Verification</VerificationText>
            </SuccessWrapper> : null
        }
    </Wrapper>;
}

export default RPD;


const InfoText = styled.p`
    font-size:14px;
`;




const Wrapper = styled.div`
    width:100%;
    height:100%;
    overflow:auto;
    display:flex;
    flex-direction:column;
    justify-content: start;
    align-items: center;
    overflow:auto;
    
    @media screen and (min-width:720px) {
        display: flex;
        justify-content: start;
        align-items: center;
        flex-direction:column;
        padding:0.5rem;
        padding-top:12px;
    }

     @media screen and (max-width:720px) {
        padding-bottom: 4rem;
    }
    
    .open-new-window{
        padding:8px;
        font-size:16px;
    }

    .loader-wrapper{
        display:flex;
        align-items: center;
    }
    .rpd-qr-code {
        width:100%;
        max-width:620px;
        height:500px;
        margin-top: 12px;
        @media screen and (max-width:720px) {
          flex-grow:1;
        }
    }
     .upi-link-code{
        margin: 12px 12px 12px 0;
        font-size:16px;
        text-decoration:none;
        color:#000;
        padding:8px 20px;
        background:rgb(255, 255, 255);
        border-radius:8px;
        border:1px solid rgb(226, 230, 233);
        flex-grow:1;
        display:flex;
        justify-content: start;
        align-items: center;
    }
    .padded-text{
        margin:4px 8px;
        @media screen and (max-width:720px) {
            margin:4px 16px;
        }
    }
    .upi-logo {
        height:36px;
        width:36px;
        margin-right: 16px;
    }

    .upi-link-android {
        font-size:16px;
        flex-grow:1;
        margin-bottom:24px;
        max-height:48px;
        padding: 2rem 0;
        background: #6725F4;
        border-radius: 52px !important;
        width: 100%;
        margin-top: 3rem;
        color: #ffffff;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .sub-text{
        width:100%;
        text-align:center;
    }
    .enter-manually-btn {
        background:#fff;
        color:#6725F4;
        margin: 0;
        width: max-content;
        font-size:14px;
        font-weight:bold;
        border:1px solid #6725F4;
        padding:18px 24px;
        display:flex;
        justify-content: center;
        align-items: center;
        min-width:250px;
    }
    .manual-selection-or-txt {
        margin:1rem 0;
        font-size:16px;
    }

    .enter-manually-btn{
        img {
            color: #6725F4;
        }
    }
`;
const Heading = styled.h1`
    font-size:18px;
    font-weight:500;
    text-align:center;
`;

const IosLinks = styled.div`
    display:flex;
    flex-direction:column;
    justify-content : start;
    align-items:center;
    .upi-link-code{
        flex-grow:1;
        width:100%;
    }
`;

const UpiAppIcon = styled.img`
    height:32px;
    width:32px;
`;

const P = styled.p`
    font-size:18px;
    margin-bottom: 0;
`;

const UpiIcons = styled.div`
display: flex;
justify-content: center;
align-items: center;
margin: 0rem 0 1.5rem 0;
width:100%;

.upi-logo {
    height:32px;
    width:32px;
    margin-right: 16px;
}

.upi-logo-paytm {
    height:38px;
    width:60px;
}

.upi-logo-whatsapp{
    width:52px;
    height:40px;
    margin-right:0;
}

`;

const DesktopQRCode = styled.div`
    margin:1rem 0;
    display:flex;
    justify-content: center;
    flex-direction:column;
    align-items: center;
    width:100%;
 
    .desktop-qr-code{
        border:1px solid #4819af;
        padding:1rem;
    }
`;


const SuccessWrapper = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 24px;
    margin-top: 20px;
    text-align: center;
`;

const SuccessText = styled.h2`
    color: #2E7D32;
    font-size: 24px;
    margin-bottom: 16px;
`;

const Amount = styled.div`
    font-size: 32px;
    font-weight: bold;
    margin-bottom: 8px;
`;

const VerificationText = styled.div`
    font-size: 16px;
    color: #666;
    margin-bottom: 16px;
`;
const IosLinksContainer = styled.div`
    width:100%;
    padding:0 16px;
`;
const MockBtn = styled.button`
    padding:8px;
    margin:8px;
`;

const MockPaymentBtns = styled.div`
    display: flex;
    align-items: center;
    .mock-success{
        background:#2afd9b;
    }
    .mock-failure{
        background:#f8343d;
    }
`;

const ArrowIcon = styled.img`
    height:16px;
    width:16px;
    margin-left:auto;
`;