/**
Author - Brijesh Pandey
Git - https://bitbucket.org/__brijesh/
**/

import React, {
  useEffect, useState, useContext, useRef
} from "react";
import styled from "styled-components";
import env from "env";

import {
  getKycStatus,
  skipPartnerSubFlow,
  partialResetFlow
} from "api";
import {
  KycScreenMapping,
} from "types";
import {
  handleApiError
} from "helpers";
import {
  FLOW_DISPLAY_NAME
} from "constants/FlowDisplayNames";

import IMAGES from 'constants/Images';

import {
   ClientAppCommunicationContext
} from "context/CommunicationsContext";
import {
 isInAppWebview,
 getSource
} from "utils/ClientAppUtils";

import {
  BankDetails,
  CheckDigiFlow,
  NomineeDetails,
  InitiateApiCalls,
  PersonalDetails,
  SuccessScreen,
  FaceMatch,
  FnoDeclarations,
  PartnerKycSuccessScreen,
  PartnerBankDetails,
  CheckArn,
  DocumentVerification,
  PartnerAgreement,
  PartnerGst,
  BankProof,
  Optional,
  Address,
  AddressProof,
  OpsEvaluation,
  Passport,
  TradingPreferences,
  ArnCardUpload,
  EuinCardUpload,
  Choice,
  UploadIdsContainer,
  FATCAForm
} from "screens";

import {
  ClientStyles,
  PartnerGlobalStyles,
  GlobalStyles
} from "../static/";

import {
  Spinner,
  Error
} from "screens/commons";
import RPD from "screens/RPD";


const RouterContainer = () => {
  const [kycData, setKycData] = useState({
    status: "incomplete",
    stage: "",
    flow: "mf",
    // hasSubFlow: true,
    // subFlow: {
    //   ctaName: "Activate F&O",
    //   redirectUrl: "https://kyc.wealthydev.in/redirect?token=Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJzdWIiOiJreWNfMDg2MGM1MjZjODkyOWMiLCJleHAiOjE2ODU2NDE0Njl9.8b3r8cJCi0cR2-klrfpbFGwfrEcJwgiSC-uI6xFsOIE"
    // }
    // prefill: {
    //   "beneficiaryAccountIfsc": "BARB0CITYLT",
    //   // "beneficiaryAccountNo": "123456789012",
    //   "name": "S N Singh",
    //   "dob": "1954-08-01",
    //   "relation": "Father",
    //   "isMinor": true,
    //   "guardianDob": "1990-01-01",
    //   "guardianName": "Brijesh"
    // }
  });

  const [isLoading, setIsLoading] = useState(false);
  const [isError, setIsError] = useState(false);
  const [skipStep, setSkipStep] = useState(0);
  const clientAppCommsContext = useContext(ClientAppCommunicationContext);
  const scrollRef = useRef(null);

  const isOpenedInClientApp = isInAppWebview();

  useEffect(() => {
    updateStatus();
  }, []);

  useEffect(() => {
    window.sessionStorage.setItem("stage", kycData.stage);
    if (window.sessionStorage.getItem("kycId") === "undefined" || window.sessionStorage.getItem("kycId") === null) {
      window.sessionStorage.setItem("kycId", kycData.kycId);
    } else if (window.sessionStorage.getItem("kycId") !== kycData.kycId) {
      window.sessionStorage.setItem("kycId", kycData.kycId);
    }
  }, [kycData]);

  useEffect(() => {
    if (clientAppCommsContext && clientAppCommsContext.subscribeToEvents) {
      clientAppCommsContext?.subscribeToEvents(handleClose);
    }
  }, [clientAppCommsContext]);

  const scrollIntoView = () => {
    if (!scrollRef?.current) return;
    scrollRef?.current?.scrollIntoView({behavior:"smooth"});
  };

  const updateStatus = async () => {
    try {
      setIsLoading(true);
      const response = await getKycStatus();
      const result = response.data;
      setKycData({
        ...kycData,
        ...result
      });
    
      if(result?.stage === 'COMPLETE'){
      clientAppCommsContext.sendEventsToClientApp("REFRESH");
    }
      scrollIntoView();
    } catch (error) {
      handleApiError(error);
      setIsError(true);
    } finally {
      setIsLoading(false);
    }
  };

  const handleSkipStep = (step) => {
    setSkipStep(step);
  };

  const handleClose = () => {
    const redirectUrl = window.sessionStorage.getItem("redirectTo");
    const source = getSource();
    
    //TODO: Find a proper fix for mobile web view back nav
    if(redirectUrl.includes("/client/profile/edit")){
      window.location.assign(`${env.VALKYRIE_URL}/edit?source=${source}`);
    } else if (redirectUrl.includes("/client/profile")){
      window.location.assign(`${env.VALKYRIE_URL}?source=${source}`);
    }
   else  clientAppCommsContext.sendEventsToClientApp("CLOSE");
  };

  const skipPartnerStep = async () => {
    try {
      setIsLoading(true);
      await skipPartnerSubFlow();
      await updateStatus();
    } catch (error) {
      handleApiError(error);
      setIsError(true);
    } finally {
      setIsLoading(false);
    }
  };

  const handleErrorState = (value) => {
    setIsError(value);
  };

  const getStepWiseClassName = () => {
    switch (kycData.stage) {
      case "OPS_EVALUATION":
        return 'ops-evaluation-component-wrapper';
      case "RPD":
        return 'rpd-component-wrapper';
      default:
        return "";
    }
  };

  const handleGoBack = async (reasons) => {
    try {
      const data = {
        kyc_id: kycData.id,
        rejections: {
          reasons: reasons
        }
      };
      await partialResetFlow(data);
      updateStatus();
    } catch (error) {
      setIsError(error);
    }
  };

  const partnerKycComponentMapping = {
    "DIGILOCKER": <InitiateApiCalls kycData={kycData} updateStatus={updateStatus} />,
    "SELFIE": <InitiateApiCalls kycData={kycData} updateStatus={updateStatus} />,
    "PAN_ANALYSIS": <InitiateApiCalls kycData={kycData} updateStatus={updateStatus} />,
    "CHOICE": <CheckDigiFlow kycData={kycData} updateStatus={updateStatus} />,
    "UPLOAD_PAN": <InitiateApiCalls handleErrorState={handleErrorState} kycData={kycData} updateStatus={updateStatus} />,
    "CHECK_ARN": <CheckArn kycData={kycData} updateStatus={updateStatus} skipPartnerStep={skipPartnerStep} checkArn={true} />,
    "META_FORM": <DocumentVerification kycData={kycData} updateStatus={updateStatus} skipPartnerStep={skipPartnerStep} />,
    "AGREEMENT": <PartnerAgreement kycData={kycData} updateStatus={updateStatus} skipPartnerStep={skipPartnerStep} />,
    "GST_DETAILS": <PartnerGst kycData={kycData} updateStatus={updateStatus} skipPartnerStep={skipPartnerStep} />,
    "PENNY_DROP": <PartnerBankDetails kycData={kycData} updateStatus={updateStatus} skipPartnerStep={skipPartnerStep} />,
    "BANK_PROOF": <BankProof updateStatus={updateStatus} handleGoBack={handleGoBack} isPartnerFlow={true}/>,
    "COMPLETE": <PartnerKycSuccessScreen kycData={kycData} updateStatus={updateStatus} skipPartnerStep={skipPartnerStep} />,
    "ADDRESS": <Address kycData={kycData} updateStatus={updateStatus} />,
    "ADDRESS_PROOF": <AddressProof updateStatus={updateStatus} />,
    'OPS_EVALUATION': <OpsEvaluation />,
    'PASSPORT': <Passport updateStatus={updateStatus} />,
    'TRADING_PREFERENCES': <TradingPreferences updateStatus={updateStatus} />,
    "UPLOAD_ARN": <ArnCardUpload kycData={kycData} updateStatus={updateStatus} skipPartnerStep={skipPartnerStep}/>,
    "UPLOAD_EUIN": <EuinCardUpload updateStatus={updateStatus} />,
    "ARN_CHOICE": <CheckArn kycData={kycData} updateStatus={updateStatus} skipPartnerStep={skipPartnerStep} checkArn={false} />,
    'RPD': <RPD updateStatus={updateStatus} />
  };

  const kycComponentMapping = {
    "DIGILOCKER": <InitiateApiCalls kycData={kycData} updateStatus={updateStatus} />,
    "SELFIE": <InitiateApiCalls kycData={kycData} updateStatus={updateStatus} />,
    "E_SIGN": <InitiateApiCalls kycData={kycData} updateStatus={updateStatus} />,
    "DDPI": <InitiateApiCalls kycData={kycData} updateStatus={updateStatus} />,
    "SPECIMEN_SIGN": <InitiateApiCalls kycData={kycData} updateStatus={updateStatus} />,
    "FACE_MATCH": <FaceMatch kycData={kycData} updateStatus={updateStatus} />,
    "UPLOAD_PAN": <InitiateApiCalls kycData={kycData} updateStatus={updateStatus} />,
    "NOMINEE": <NomineeDetails kycData={kycData} updateStatus={updateStatus} />,
    "PENNY_DROP": <BankDetails kycData={kycData} updateStatus={updateStatus} />,
    "PERSONAL_DETAILS": <PersonalDetails updateStatus={updateStatus} kycData={kycData} />,
    "INCOME_DECLARATION": <FnoDeclarations updateStatus={updateStatus} />,
    "COMPLETE": <SuccessScreen kycData={kycData} updateStatus={updateStatus} />,
    "BANK_PROOF": <BankProof updateStatus={updateStatus} handleGoBack={handleGoBack}  isPartnerFlow={false}/>,
    "ADDRESS": <Address kycData={kycData} updateStatus={updateStatus} />,
    "IDS_CHOICE": <Choice kycData={kycData} updateStatus={updateStatus} />,
    "UPLOAD_IDS":  <UploadIdsContainer updateStatus={updateStatus} />,
    "ADDRESS_PROOF": <AddressProof updateStatus={updateStatus} />,
    'OPS_EVALUATION': <OpsEvaluation />,
    'PASSPORT': <Passport updateStatus={updateStatus} />,
    'TRADING_PREFERENCES': <TradingPreferences updateStatus={updateStatus} />,
    'FATCA': <FATCAForm updateStatus={updateStatus} />,
    'RPD': <RPD updateStatus={updateStatus} />
  };

  let globalStyles = <ClientStyles />;
  let componentMapping = {};

  switch (kycData.flow) {
    case 'PARTNER':
    case 'PARTNER_ARN':
    case 'PARTNER_GST':
    case 'PARTNER_BANK':
      globalStyles = <PartnerGlobalStyles />;
      componentMapping = partnerKycComponentMapping;
      break;

    default:
      globalStyles = <ClientStyles />;
      componentMapping = kycComponentMapping;
      break;
  }

  let component = <></>;
  if (kycData.isOptional && skipStep === 0) {
    component = <Optional updateStatus={updateStatus} handleSkipStep={handleSkipStep} skipStep={skipStep} />;
  } else {
    switch (kycData.stage) {
      case KycScreenMapping[kycData.stage]:
        component = componentMapping[KycScreenMapping[kycData.stage]];
        break;
      default:
        component = <InitiateApiCalls kycData={kycData} updateStatus={updateStatus} />;
        break;
    };
  }
  const isClientCompletionPage = kycData.stage === 'OPS_EVALUATION';

  const get_flow_heading = () => {
    let formattedName = kycData.fullName.toLowerCase().split(' ').map(word => word.charAt(0).toUpperCase() + word.slice(1)).join(' ');
    const arr = [kycData.crn, formattedName, FLOW_DISPLAY_NAME[kycData.flow]];
    return arr.filter(Boolean).join(', ');
  };

  const isPartnerFlow = kycData.flow === FLOW_DISPLAY_NAME.PARTNER;

  return (
    <>
      {globalStyles}
      <GlobalStyles />
      <ComponentsWrapper className="component-wrapper">
        <LogoAndFlowName ref={scrollRef} className={`${isClientCompletionPage ? "client-completion-page-logo-flow-name" : ""} ${isPartnerFlow ? "partner-completion-page-logo-flow-name" : "client-flow-logo-name"} mt-4`}>
          {!isClientCompletionPage && !isOpenedInClientApp ? <Image src={IMAGES.mainColorWealthyLogo} /> : null}
          {
            isPartnerFlow ? null : (
              <FlowName className="flow-name">
                {FLOW_DISPLAY_NAME[kycData.flow] ? `${get_flow_heading()} KYC` : ""}
              </FlowName>
            )
          }
          {isOpenedInClientApp ?
            <CloseBtn><CloseIcon onClick={handleClose} alt="close image" height={16} width={16} src={IMAGES.crossIcon} /> </CloseBtn> : null}
        </LogoAndFlowName>
        {
          isError ? (
            <ErrorContainer>
              <Error />
            </ErrorContainer>
          ) : (
            <>
              {
                  isLoading ? (
                  <LoaderContainer>
                    <Spinner
                      color="purple"
                      message="Please wait while we fetch your kyc status, We request you to not to close the window for smooth process"
                    />
                  </LoaderContainer>
                ) : (
                  <ComponentWrapper className={`${getStepWiseClassName()} ${isPartnerFlow ? "partner-componet-wrapper" : ""}`}>
                    {component}
                  </ComponentWrapper>
                )
              }
            </>
          )
        }
        {/* <ComponentWrapper> */}
        {/* <Optional updateStatus={updateStatus} handleSkipStep={handleSkipStep} skipStep={skipStep} />; */}
        {/* <DocumentVerification /> */}
        {/* <FnoDeclarations updateStatus={updateStatus} /> */}
        {/* <SuccessScreen kycData={kycData} updateStatus={updateStatus} /> */}
        {/* <Kyc /> */}
        {/* <DigioFlow updateStatus={updateStatus} /> */}
        {/* <Facefinder /> */}
        {/* <ArnDetection /> */}
        {/* <PartnerAgreement /> */}
        {/* <PersonalDetails /> */}
        {/* <Otp /> */}
        {/* <BankDetails kycData={kycData} /> */}
        {/* <NomineeDetails updateStatus={updateStatus} kycData={kycData} /> */}
        {/* <ReviewDetails /> */}
        {/* {component} */}
        {/* <CheckDigiFlow /> */}
        {/* <InitiateApiCalls kycData={kycData} updateStatus={updateStatus} /> */}
        {/* <PartnerBankDetails /> */}
        {/* <SuccessScreen kycData={kycData} updateStatus={updateStatus} /> */}
        {/* <PartnerKycSuccessScreen kycData={kycData} updateStatus={updateStatus} /> */}
        {/* <ComponentWrapper className="component-wrapper">
          <FATCAForm updateStatus={console.log} />
          </ComponentWrapper> */}

        {/* </ComponentWrapper> */}
        {/* <TradingPreferences/> */}
      </ComponentsWrapper>
    </>
  );
};

const ComponentsWrapper = styled.div`
  height: 100vh;
  width:100vw;
  display: flex;
  flex-direction:column;
  overflow:auto;

  .custom-ion-page {
    overflow: auto;
  }
  
  .client-completion-page-logo-flow-name {
    position: absolute;
    top:0;
    color:white;
    z-index: 10;
    .flow-name{
      background-color: rgb(30, 23, 48);
      color:white !important;
    }
}

.rpd-component-wrapper {
    margin:12px;
    width:100%;
    height:max-content;
    margin:0;
    padding:0;
    overflow:auto;

    @media screen and (min-width:721px) {
      max-width:800px;
      max-height:98%;
      align-self:center;
      margin:auto;
    }
 }

.client-flow-logo-name{
  width:100%;
  text-align: center;
  .flow-name{
    width: 100%;
  }
}

.partner-completion-page-logo-flow-name{
  align-items: flex-start;
  width: 35%;
  margin: 2.5rem auto;
  @media (min-width:768px) {
    min-width: 520px;
  }
}
`;

const ComponentWrapper = styled.div`
    flex-grow:1;
    width: 35%;
    margin: 2.5rem auto 2.5rem;
    background: #ffffff;
    border-radius: 1rem;
    box-shadow: 0 10px 25px #d0d0d06e;
    padding: 2rem;

    &.partner-componet-wrapper{
      margin-top: 0rem;
      background: unset;
      box-shadow: unset;
    }

  @media (max-width: 768px) {
    width: 100%;
    margin: 0;
    box-shadow: unset;
    padding: 0;
    border-radius: 0;
  }

  @media (min-width:768px) {
    min-width: 520px;
  }
`;

const ErrorContainer = styled.div`
  background: #ffffff;
  height: 100vh;
  width: 100%;
  scroll-behavior: scroll;
  position: relative;
  overflow-y: scroll;
  padding: 2.4rem;
  text-align: center;

  .error-image {
    width: 60%;
    margin-bottom: 5rem;
  }

  @media (max-width: 768px) {
    background: white;
    .error-image {
      width: 90%;
    }
  }
`;

const LoaderContainer = styled.div.attrs({
  className: 'description'
})`
  height: 80vh;
  display: flex;
  justify-content: center;
  align-items: center;
  
  width: 40%;
  margin: 5rem auto 0;
  overflow: scroll;
  border-radius: 2rem;
  ${'' /* box-shadow: 0 0 20px 8px #d0d0d0; */}
  padding: 2rem;
  ${'' /* background: #F8F4FF; */}

  @media (max-width: 768px) {
    width: 100%;
    margin: 0;
    box-shadow: unset;
    ${'' /* background: #ffffff; */}
  background: #F8F4FF;
    padding: 0;
    border-radius: 0;
  }

  img {
    width :5rem;
    height: unset;
    transform: unset;
  }
`;

const LogoAndFlowName = styled.p`
  color:  #000;
  ${'' /* font-family: ; */}
  font-size: 2rem;
  font-style: normal;
  font-weight: 400;
  line-height: 24px; 
  width:35%;
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  padding: 1rem 4rem 0 4rem;
  flex-direction: column;
  ${'' /* margin-bottom: 0 !important; */}
  position: relative;
`;

const FlowName = styled.p`
  margin-bottom: 0 !important;
  color: black;
   @media screen and (max-width: 768px) {
    font-size:16px;
  }
`;
const Image = styled.img`
  height:4rem;
  width: 18rem;
  margin-bottom: 1rem;

  @media screen and (max-width: 768px) {
    height:2rem;
    width:10rem;
  }
`;

const CloseIcon = styled.img`
  object-fit: "contain";
`;

const CloseBtn = styled.button`
  padding:0.7rem;
  flex-shrink:0;
  position: absolute;
  right:2.5rem;
  top:0.4rem;

  @media screen and (max-width: 380px) {
    right:1rem;
  }
  background:transparent;
  border:none;
  outline:none;
`;

export default RouterContainer;
