/**
Author - Brijesh Pandey
Git - https://bitbucket.org/__brijesh/
**/



import React from "react";

import styled from "styled-components";
import PropTypes from "prop-types";

import spinnerWhite from "./assets/spinnerWhite.svg";
import spinnerBlue from "./assets/spinnerBlue.svg";
import spinnerPurple from "./assets/spinnerPurple.svg";

const propTypes = {
  className: PropTypes.string,
  color: PropTypes.string,
  message: PropTypes.string,
  wrapperClassName: PropTypes.string
};

const Spinner = ({
  className,
  color,
  message,
  wrapperClassName
}) => (
    <Wrapper className={`custom-loading-spinner ${wrapperClassName}`}>
      <Img
        alt="Loading"
        className={className || ""}
        src={color === "blue" ? spinnerBlue : color === "purple" ? spinnerPurple : spinnerWhite}
      />
      <Message>{message}</Message>
    </Wrapper>
  );

const Img = styled.img`
  height: 15px;
  width: 2.6rem;
  transform: scale(1.8);
  position: relative;
  margin: 1rem auto;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const Message = styled.div`
  font-size: 1.8rem;
  font-family: MavenPro;
  font-weight: 500;
  line-height: 1.2;
  text-align: center;

  @media (max-width: 768px) {
    width: 85%;
    margin: 0 auto;
  }
`;

const Wrapper = styled.div``;

export const LoaderContainer = styled.div`
  height: 80vh;
  display: flex;
  justify-content: center;
  align-items: center;
`;


Spinner.propTypes = propTypes;

export default Spinner;
